import React, { useState } from "react";
import { useTheme } from "styled-components";

import PreloadSearchArticleList2ColumnBlock from "../PreloadSearchArticleList2ColumnBlock";

const SearchArticleList2ColumnList = ({
  keyword
}) => {
  const theme = useTheme();
  const [pageCount, setPageCount] = useState(1);
  const [pageOffset, setPageOffset] = useState([0]);
  const [existsArticles, setExistsArticles] = useState(true);

  function countupPage(next_offset) {
    setPageCount(pageCount + 1);
    if(pageOffset[pageCount] !== next_offset) {
      pageOffset.push(next_offset);
      setPageOffset(pageOffset);
    }
  }

  const article_list = []
  const articles_per_load = 20; // 取得記事数
  for(let target=0; target<pageCount; target++) {
    article_list.push(
      <PreloadSearchArticleList2ColumnBlock
        offset={pageOffset[target]}
        length={articles_per_load}
        keyword={keyword}
        after_show={(next_offset) => { countupPage(next_offset) }}
        key={`sl2cb-${target}`}
        first_page={target===0}
        adpage={target+1}
        //広告は6番目と12番目(広告が挿入されるので11ではなく10)
        insert_infeed={[5, 10, 15, 20]}
        exists_articles={(bool) => { setExistsArticles(bool) }}
    />
    );
  }

  return (
    <>
      {!existsArticles ? '記事がみつかりませんでした。' :
        <ul className={`p-${theme.page}__article-list m-article-list-2column`}>
          {article_list}
        </ul>
      }
    </>
  )
};

export default SearchArticleList2ColumnList;