import React, { useContext, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import Consts from '../../../config/Consts';
import { UserContext } from "../../../contexts/UserContext";
import { QueryStringContext } from "../../../contexts/QueryStringContext";

const HelmetTemplate = (params) => {
  const theme = useTheme();
  const { pathname } = useContext(QueryStringContext);
  const { userInfo } = useContext(UserContext);

  let str = 'var F1H_CUSTOM_KEY_VALUE={'
  // TOPICIDを広告サーバーに連携する（分析やカテゴリ指定配信のため）
  // その記事や一覧ページが属するTOPICをすべて設定する。
  if(params.topics && params.topics.length > 0) {
    let topic_str = ''
    for(let topic of params.topics) {
      topic_str += ',\''+topic+'\''
    }
    str += 'TOPIC:[' + topic_str.substring(1) + '],'
  }
  // 記事ページにて記事ID（難読化記事ID）（pageURLに利用される記事ID）を
  // 広告サーバーに連携する（分析や特定記事ジャックなどの用途のため）
  if(params.article_id) {
    str += 'KJID:\'' + params.article_id + '\','
  }
  // ページの種別を判別できるように
  // 記事ページには「PTYPE」を「article」という文字列を指定して欲しい
  if(theme.page === 'contents') {
    str += 'PTYPE:\'article\','
  }
  try {
    var item = localStorage.getItem('nikkei-segment-ids');
    var data = typeof item === 'string' ? JSON.parse(item) : null;
    if (data && data.expired >= Date.now()) {
      let segmentIds_str = ''
      if(data.segmentIds && data.segmentIds.length > 0) {
        for(let id of data.segmentIds) {
          segmentIds_str += ',\''+id+'\''
        }
        segmentIds_str = segmentIds_str.substring(1)
      }
      str += 'NSEG:[' + segmentIds_str + '],'
    }
  } catch (e) {
    //console.log(e);
  }
  str += 'opectx:getMediaconsortiumOpectx, opeaud:getMediaconsortiumOpeaud};'

  const [customKeyValue, setCustomKeyValue] = useState(str);
  const [customRtValue, setCustomRtValue] = useState(null);

  useEffect(() => {
    if(userInfo.set === 1) {
      let newSubstr = 'F1H_CUSTOM_KEY_VALUE={'
      // 1 :1:未ログイン
      // 2:日経ID会員でログイン（マガジン未入会）
      // 3:マガジン会員でログイン（日経IDかつマガジン会員）
      if(userInfo.status === 0) {
        newSubstr += 'NMR:\'1\','
      } else if(userInfo.status === 1) {
        newSubstr += 'NMR:\'2\','
      } else if(userInfo.status === 2) {
        newSubstr += 'NMR:\'3\','
      }
      setCustomKeyValue(customKeyValue.replace('F1H_CUSTOM_KEY_VALUE={',newSubstr))

      let rt_str = 'var rt_data = {custom_RtTNMuser: '
      if(userInfo.status === 0) {
        rt_str += "0"
      } else if(userInfo.status === 1) {
        rt_str += "1"
      } else if(userInfo.status === 2) {
        rt_str += "1"
      }
      rt_str += '};'
      const rt_script = `
        ${rt_str}
        !function(r,t,j,s){(j=r.createElement(t)).type="text/javascript",
        j.async=!0,j.charset="utf-8",j.src="//js.rtoaster.jp/RTA-eca3-4a54d25fe9a1/rt.js",
        (s=r.getElementsByTagName(t)[0]).parentNode.insertBefore(j,s)
        }(document,"script")
      `;
      setCustomRtValue(rt_script)
    }
  }, [userInfo]);

  const custom_key = `
    function getMediaconsortiumOpectx() {
      if (typeof mediaconsortium_keywords !== 'undefined' && typeof mediaconsortium_keywords['opectx'] !== 'undefined'){
        return mediaconsortium_keywords['opectx'];
      } return '';
    }
    function getMediaconsortiumOpeaud() {
      if (typeof mediaconsortium_keywords !== 'undefined' && typeof mediaconsortium_keywords['opeaud'] !== 'undefined'){
        return mediaconsortium_keywords['opeaud'];
      } return '';
    }
    ${customKeyValue}
  `;

  let og_url = Consts.COMMON.BASE_URL
  if(pathname && pathname.length > 1) {
    og_url += pathname
  }
  return(
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X UA Compatible" content="IE=edge,chrome=1" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="noodp" />
      <meta name="bingbot" content="noarchive" />
      <meta name="ps:tuid" content="" />
      <title>THE NIKKEI MAGAZINE｜日本経済新聞社</title>
      <meta name="description" content="" />
      <meta name="keywords" content="" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content="THE NIKKEI MAGAZINE｜日本経済新聞社" />
      <meta property="og:site_name" content="THE NIKKEI MAGAZINE" />
      <meta property="og:description" content="" />
      <meta property="og:image" content={`${Consts.COMMON.BASE_URL}${Consts.LINK.SITE_IMAGE}`} />
      <meta property="og:url" content={og_url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@NIKKEI_MAGAZINE" />
      <meta property="fb:app_id" content="1629691260881631"/>

      <link rel="shortcut icon" href="" type="image/x-icon" />
      <link rel="apple-touch-icon" href="/images/tnm_webclip.png" />
      <link rel="icon" type="image/png" href="/images/tnm_logo.png" />

      {/* google fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;600&family=Noto+Sans+JP:wght@300;400;500;700&family=Noto+Serif+JP:wght@600&family=Roboto:wght@500;700&display=swap" rel="stylesheet" />
      <link rel="canonical" href="https://magazine.nikkei.com" />

      <script>{custom_key}</script>
      <script>{customRtValue}</script>
      <script src="https://partsa.nikkei.com/static/nkis/js/foneH.js"></script>

    </Helmet>
  );
}

export default HelmetTemplate;