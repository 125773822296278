import React, { useState } from "react";
import { useTheme } from "styled-components";

import PreloadArticleList2ColumnBlock from "../PreloadArticleList2ColumnBlock";

const ArticleList2ColumnList = ({
  topic_id
}) => {
  const theme = useTheme();
  const [pageCount, setPageCount] = useState(1);
  const [pageOffset, setPageOffset] = useState([0]);

  function countupPage(page_info) {
    setPageCount(pageCount + 1);
    if(pageOffset[pageCount] !== page_info.next_offset) {
      pageOffset.push(page_info.next_offset);
      setPageOffset(pageOffset);
    }
  }

  const article_list = []
  if(topic_id.length > 0) {
    const articles_per_load = 20; // 取得記事数
    for(let target=0; target<pageCount; target++) {
      article_list.push(
        <PreloadArticleList2ColumnBlock
          topic_id={topic_id}
          adpage={target+1}
          //広告は6番目と12番目(広告が挿入されるので11ではなく10)
          insert_infeed={[5, 10, 15, 20]}
          offset={pageOffset[target]}
          length={articles_per_load}
          after_show={(page_info) => { countupPage(page_info) }}
          key={`al2cb-${target}`}
          first_page={target===0}
        />
      );
    }
  }

  return (
    <ul className={`p-${theme.page}__article-list m-article-list-2column`}>
      {article_list}
    </ul>
  )
};

export default ArticleList2ColumnList;