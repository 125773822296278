import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import Article2ColumnItem from "../../molecules/Article2ColumnItem";
import Article2ColumnInfeed from "../../ads/Article2ColumnInfeed";
import adid from "../../../config/adid";

const GET_ARTICLES = gql`
  query (
    $token: ID,
    $keyword: String,
    $offset:Int,
    $length: Int,
    $width: Int,
    $height: Int
  ){
    search(
      token: $token
      keyword: $keyword
      offset: $offset
      length: $length
    ){
      total
      maxPage
      article_list {
        article_id
        title
        sub_title
        wappen
        image(width:$width, height: $height){
          src
          etoki
        }
        target_link
        display_time
        sponsor
        is_new
        movie_flag
        link_type
        link_url
        direct_move_flag
        topics {
          topic_id
          label
        }
      }
    }
  }
`;

const PreloadSearchArticleList2ColumnBlock = ({
  offset, length, keyword, after_show, first_page, adpage, insert_infeed, exists_articles
}) => {
  const theme = useTheme();
  const [show, setShow] = useState(first_page);

  const { loading, error, data } = useQuery(GET_ARTICLES, {
    variables: {
      "token": "PreloadSearchArticleList2ColumnBlock",
      "keyword": keyword,
      "offset": offset,
      "length": length,
      "width": 624,
      "height": 414
    }
  });

  useEffect(() => {
    if(data && data.search && offset === 0) {
      after_show(offset+length);
      if (data.search.article_list.length === 0) {
        exists_articles(false);
      }
    }
  }, [first_page]);

  if (loading) return null;
  if (error) return null;
  if(data.search.article_list.length === 0) return null;
  // 番号をずらすために * 5
  const adindex = (insert_infeed.length * 5 * (adpage-1) ) + 1;
  let index_now;
  let inserted_infeed_count = 0;
  const infeed_contents = (_adindex,_adpage) => {
    return (
      <li className="m-article-list-2column__item u-infeed">
        <Article2ColumnInfeed adid={adid.PC[theme.adpagetype].INFEED} index={_adindex} page_index={_adpage} device="pc" />
        <Article2ColumnInfeed adid={adid.SP[theme.adpagetype].INFEED} index={_adindex} page_index={_adpage} device="sp" />
      </li>
    )
  }
  const article_items = data.search.article_list.map((article, index) => {
    const tmp_article = JSON.parse(JSON.stringify(article));
    // 飛び先リンクのセット
    if (article.link_url) {
      tmp_article.target_link = article.link_url;
    }
    const props = {
      article: tmp_article,
      page: "search",
      itemIndex: index + 1 + ((adpage - 1) * length)
    }
    let infeed = "";
    if(insert_infeed) {
      if(insert_infeed.indexOf(index + 1) !== -1) {
        inserted_infeed_count++;
        infeed = infeed_contents(adindex+index, adpage);
      }
    }
    index_now = index;
    return (
      <React.Fragment key={`psal2c-${offset}-${index}`}>
        <Article2ColumnItem {...props} />
        {infeed}
      </React.Fragment>
    );
  });
  let infeeds = [];
  if(article_items.length < 13) {
    inserted_infeed_count += 2
  }
  while(insert_infeed.length > inserted_infeed_count) {
    inserted_infeed_count++;
    index_now++;
    infeeds.push(infeed_contents(adindex+index_now, adpage));
  };
  const remaining_infeeds = infeeds.map((infeed, i) => <React.Fragment key={'aaa' + i}>{infeed}</React.Fragment>);

  return (
    <>
      {show === false &&
        <div className={`p-${theme.page}__article-btn-more m-btn-more`}>
          <div href="" onClick={(e) => {
            after_show(offset+length);
            setShow(true);
            e.preventDefault();
          }} role="button" data-atlas-trackable="list_viewmore">VIEW MORE</div>
        </div>
      }
      {show === true &&
        <>
          {article_items}
          {remaining_infeeds}
        </>
      }
    </>
  )
};

export default PreloadSearchArticleList2ColumnBlock;