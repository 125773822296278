import React, { useContext, useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

import Article2ColumnInfeed from "../../ads/Article2ColumnInfeed";
import Article2ColumnItem from "../../molecules/Article2ColumnItem";
import adid from '../../../config/adid';
import { QueryStringContext } from "../../../contexts/QueryStringContext";

const GET_ARTICLES = gql`
  query (
    $token: ID,
    $topic_id: ID,
    $offset:Int,
    $length: Int,
    $width: Int,
    $height: Int,
    $date_staging: String
  ){
    articles(
      token: $token,
      topic_id: $topic_id,
      offset: $offset,
      length: $length,
      date_staging: $date_staging
    ){
      article {
        article_id
        title
        description
        wappen
        subchannel_name
        target_link
        display_time
        sponsor
        is_new
        movie_flag
        link_type
        direct_move_flag
        image(width: $width, height: $height, crop:true) {
          src
          etoki
          credit
        }
        topics {
          topic_id
          label
        }
      }
      page_info {
        offset
        length
        next_offset
        max
        has_next
      }
    }
  }
`;

const PreloadArticleList2ColumnBlock = ({
  topic_id, adpage, insert_infeed, offset, length, after_show, first_page
}) => {
  let inserted_infeed_count = 0;
  const theme = useTheme();
  const { queryString } = useContext(QueryStringContext);
  const date_staging = typeof queryString.k !== 'undefined' ? queryString.k : ''
  const [show, setShow] = useState(first_page);

  const { loading, error, data } = useQuery(GET_ARTICLES, {
    variables: {
      "token": "PreloadArticleList2ColumnBlock",
      "topic_id": topic_id,
      "offset": offset,
      "length": length,
      "width": 624,
      "height": 414,
      "date_staging": date_staging
    }
  });

  useEffect(() => {
    if(data && data.articles && offset === 0) {
      after_show(data.articles.page_info);
    }
  }, [first_page]);

  if (loading) return null;
  if (error) return null;
  if(data.articles.article.length === 0) return null;
  // 番号をずらすために * 5
  const adindex = (insert_infeed.length * 5 * (adpage-1) ) + 1;
  let index_now;
  const infeed_contents = (_adindex,_adpage) => {
    return (
      <li className="m-article-list-2column__item u-infeed">
        <Article2ColumnInfeed adid={adid.PC[theme.adpagetype].INFEED} index={_adindex} page_index={_adpage} device="pc" />
        <Article2ColumnInfeed adid={adid.SP[theme.adpagetype].INFEED} index={_adindex} page_index={_adpage} device="sp" />
      </li>
    )
  }
  const article_items = data.articles.article.map((article, index) => {
    const props = {
      article: article,
      page: "article",
      itemIndex: index + 1 + ((adpage - 1) * length)
    }
    let infeed = "";
    if(insert_infeed) {
      if(insert_infeed.indexOf(index + 1) !== -1) {
        inserted_infeed_count++;
        infeed = infeed_contents(adindex+index, adpage);
      }
    }
    index_now = index;
    return (
      <React.Fragment key={`pal2c-${offset}-${index}`}>
        <Article2ColumnItem {...props} />
        {infeed}
      </React.Fragment>
    );
  });
  let infeeds = [];
  if(article_items.length < 13) {
    inserted_infeed_count += 2
  }
  while(insert_infeed.length > inserted_infeed_count) {
    inserted_infeed_count++;
    index_now++;
    infeeds.push(infeed_contents(adindex+index_now, adpage));
  };
  const remaining_infeeds = infeeds.map((infeed, i) => <React.Fragment key={'aaa' + i}>{infeed}</React.Fragment>);

  return (
    <>
      {show === false &&
        <div className={`p-${theme.page}__article-btn-more m-btn-more`}>
          <div href="" onClick={(e) => {
            after_show(data.articles.page_info);
            setShow(true);
            e.preventDefault();
          }} role="button" data-atlas-trackable="list_viewmore">VIEW MORE</div>
        </div>
      }
      {show === true &&
        <>
          {article_items}
          {remaining_infeeds}
        </>
      }
    </>
  )
};

export default PreloadArticleList2ColumnBlock;